.dropZoneLoader {
    margin: 20px;
    border: 4px solid #3995d2; /* Light grey */
    border-top: 4px solid #96c667; /* Blue */
    border-radius: 50%;
    padding: 5px;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: #96c667;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #3995d2;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
}