.css-9tj150-MuiButton-endIcon {
    margin: 0 !important;
    padding: 0 !important;
  }
  .advanceSearch__weight {
    padding-bottom: 0.5rem;
  }
  .advanceSearch__weight--fields {
    max-width: 40%;
  }
  .advanceSearch__searchBtn--fontSize {
    font-size: 1.4rem;
  }
  